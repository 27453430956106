body {
  font-family: "Muli", sans-serif !important;
  background-color: #f5f6fa !important;
  color: #414141;
  /* padding-right: 15px; */
}

/* Active textfield border & shadow */
/* input:focus,
textarea:focus,
select:focus {
  border: 1px solid #31394d !important;
  box-shadow: 0 0 4px #31394d !important;
  -moz-box-shadow: 0 0 4px #31394d !important;
  -webkit-box-shadow: 0 0 4px #31394d !important;
} */

h4 {
  color: #4a4a4a;
}

h4.page-title {
  font-weight: 800;
}

h4.module-title {
  font-weight: 700;
}

.module-figure {
  text-align: center;
}

.module-figure h4 {
  font-weight: 700;
  font-size: 2rem;
}

h5 {
  color: #4a4a4a;
}

button.btn-gradient {
  /* background: linear-gradient(135deg, #2c4b8a, #152340); */
  border: none;
}

.sidebar.open img {
  max-width: 200px;
}

.sidebar.closed img {
  max-width: 100%;
}

.secondary-navbar {
  background-color: rgba(0, 0, 0, 0.03);
}

/* .secondary-navbar a {
  color: #152340;
  font-weight: 700;
} */

/* .secondary-navbar a:hover {
  color: #152340;
} */

.secondary-navbar .navbar-nav {
  flex-direction: row !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(21,35,64,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
.navbar img {
  max-width: 200px;
}

h5.modal-title {
  font-weight: bold;
}

.remove-btn {
  margin-right: 0.5rem;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

/* Top Navbar */
.primary-nav a {
  color: #4a4a4a;
}
.primary-nav a:hover {
  color: #14337e;
}

.

/* Dashboard search bar */
/* TODO: BRING FONT DOWN, SMALLER */
.form-inline .search-field {
  color: #14337e;
  height: 40px;
  background-color: white;
  border: none;
}
/* .form-inline .search-field:focus {
  box-shadow: 0 0 10px lightgray !important;
} */
.form-inline .form-control.sf-textfield {
  border-radius: 7px 0px 0px 7px;
  width: 600px;
}
.btn-secondary.sf-button {
  border-radius: 0px 7px 7px 0px;
  font-weight: 600;
}
.btn-secondary.sf-button:hover {
  color: #14337e;
  background-color: white;
}

/* Dashboard cards */
.row .card {
  background-color: white;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 7px;
  padding: 1rem;
  margin-bottom: 1rem;
}

/* Projects List */

/* TODO: Change textfield style to gray when inactive and blue with glow when active */

li.list-group-item {
  border: none;
  border-radius: 7px;
}
.projects-list a {
  color: #14337e;
}
.projects-list a:hover {
  text-decoration: none !important;
  color: #14337e;
}
.projectlist-float-right {
  float: right;
  border: none;
}
/* Project completion tick */
.projects-list a svg.incomplete {
  color: #dadada;
}
.projects-list a svg.complete {
  color: #1c8526;
}
/* Project paid status badge */
.badge.paid {
  margin-right: 1rem;
  padding: 0.5rem;
  background-color: #4a8950;
  min-width: 90px;
}
.badge.unpaid {
  margin-right: 1rem;
  padding: 0.5rem;
  background-color: #ff635f;
  min-width: 90px;
}
.projectlist-date {
  font-weight: bold;
}

/* Modals */
.modal-body form {
  padding-left: 2rem;
  padding-right: 2rem;
}
.modal-body label {
  color: #4b4b4b;
  font-weight: 500;
}

/* Project modal */
.project-modal .linediv {
  margin-bottom: 1rem;
}
/* .project-modal input {
  border: solid 1px #4b4b4b !important;
  border-radius: 10px;
} */
.project-modal input.input-file {
  border: none !important;
  border-radius: 0;
}
.project-modal button {
  border-radius: 10px;
  max-width: 35%;
  float: right;
}

.tab-pane .tab-button {
  border-radius: 10px;
  max-width: 25%;
  float: right;
}

.ov-tab-date {
  font-size: 90%;
  border: solid #14337e 1px;
  border-radius: 10px;
  padding: 0.5rem;
  color: #979797;
}

.invoice-list button {
  margin-right: 1rem;
  font-size: 90%;
  padding-top: 0.2rem;
  padding-bottom: 0.21rem;
  background-color: #14337e;
}

/* App Sidebar */
.main-content.sidebarOpen {
  width: 84%;
  margin-left: auto;
}

.main-content.sidebarClosed {
  width: 95%;
  margin-left: auto;
}

.toggle-sidebar-btn {
  cursor: pointer;
}

.sidebar {
  background-color: white;
  height: 100vh;
  width: 16%;
  position: fixed;
}

.sidebar.closed {
  width: 5%;
}

.sidebar h4 {
  /* font-size: 1.5rem; */
  font-weight: 500;
}

.sidebar .side-item {
  margin: 5px 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  color: #4a4a4a;
  font-weight: 600;
}

.sidebar .side-item.active {
  background-color: #31394d20;
}

.sidebar .side-item.active .svg-container {
  background-color: #14337e;
}

.sidebar .side-item:not(.active):hover {
  background-color: #31394d09;
}

.sidebar .new-project-button {
  margin: 5px 15px;
  outline: none;
  border: none;
  border-radius: 8px;
  width: 200px;
}

.sidebar.open hr {
  border: 1px solid #e1e1e1;
  width: 80%;
}

.nav-link div.card {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  margin-bottom: 0;
}
.nav-link.active div.card {
  background-color: #14337e;
}
.nav-link.active div.card h4 {
  color: white;
}
.nav-link div.card svg {
  width: 18px;
  height: 18px;
}
.sidebar .svg-container {
  background-color: #14337e;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.sidebar svg path {
  fill: white;
}

/*  */

.nav-link.active div.card .svg-container {
  background-color: white;
}
.nav-link.active div.card svg path {
  fill: #14337e;
}
/* New Project Button */
.link-button {
  background: linear-gradient(135deg, #2c4b8a, #152340);
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 0;
}
.secondary-navbar .link-button .nav-link {
  color: white;
}
.link-button a {
  color: white;
}
.link-button:hover,
.link-button:focus {
  background: linear-gradient(135deg, #2c4b8a, #152340);
  outline: none;
  border: none;
  padding: 0;
}

/* ----- */
.project-nav.nav-tabs {
  border-bottom: 1px solid #e1e1e1;
  font-weight: bold;
  color: #979797;
}
.project-nav .nav-link {
  border: none !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 2rem !important;
}
.project-nav.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  color: #14337e !important;
  border-bottom: 2px solid #14337e !important;
}

button.overview-button {
  float: right;
  border: none;
  background-color: transparent;
}

.greyed-out {
  color: #979797;
}

button.overview-button:focus {
  outline: 0;
}

.negative-icon {
  background-color: #fd413c15;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 5rem;
  height: 90%;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.negative-icon svg {
  font-size: 3rem;
}
.negative-icon svg path {
  fill: #ff635f;
}
.positive-icon {
  background-color: #1c852615;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 5rem;
  height: 90%;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.positive-icon svg {
  font-size: 3rem;
}
.positive-icon svg path {
  fill: #4a8950;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.login-page {
  width: 25% !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login {
  background-color: #14337e;
  height: 100vh;
}

.login-page h3 {
  font-weight: 400;
}
.login-page img {
  width: 75%;
  display: block;
  margin: auto;
}

.header-icon {
  float: left;
  background-color: #14337e;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

.more-info-button {
  float: right;
}

.toggle-test {
  float: right !important;
}

button.custom-btn {
  border-radius: 10px;
  max-width: 25%;
  float: right;
}

.center-vertically {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.news-card {
  height: 5rem;
}
.news-card h4 {
  margin: 0;
}
.num-of-news {
  font-weight: bold;
  font-size: 2.5rem;
}

.center {
  text-align: center;
}

.panel {
  border: none !important;
  border-radius: 8px !important;
  max-width: 430px !important;
  margin-bottom: 20px;
}

.login-page a.signup-link {
  color: #14337e;
}

.login-page a.signup-link:hover {
  text-decoration: none;
  color: #14337e;
}

.login-page a.forgot-pw-link {
  margin-top: 40px;
  color: white;
  text-decoration: underline;
}

.login-page a:hover {
  color: white;
}

/* Project Invoice modal */

div.invoice-downloads {
  text-align: center;
}

div.invoice-downloads svg {
  font-size: 2rem;
}
button.download-invoice-btn {
  max-width: 100%;
  float: none;
  background: linear-gradient(135deg, #2c4b8a, #152340);
  border: none;
  /* background-color: #152340; */
  margin-bottom: 1rem;
}

button.download-invoice-btn p {
  margin-bottom: 0px;
}

button.download-invoice-btn p.text-small {
  font-size: 80%;
}

button.btn-dark {
  background-color: #14337e;
  border: none;
}

button.btn-dark:hover {
  background-color: #14337e;
}

button.btn-dark:focus {
  background-color: #14337e;
}

button.btn-secondary {
  background-color: #14337e;
}

button.btn-secondary:hover {
  background-color: #14337e;
}

button.btn-secondary:focus {
  background-color: #14337e;
}

/* List Modal */

.list-modal .modal-body {
  padding: 0px;
}

.list-modal .btn {
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #4a4a4a;
  border: none;
  border-radius: 0px;
  padding: 1rem;
}

.list-modal .btn:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .btn:active {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .btn:focus {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #4a4a4a !important;
}

.list-modal .nav-link {
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #4a4a4a;
  border: none;
  border-radius: 0px;
  padding: 1rem;
}

.list-modal .nav-link p {
  margin: 0px;
}

.list-modal .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .nav-link:active {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .nav-link:focus {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #4a4a4a !important;
}

/* List Module */

.list-module.card {
  padding: 0px;
}

.list-module.card .module-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.list-module.card .module-header h4 {
  margin: 0px;
}

.list-module .btn {
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #4a4a4a;
  border: none;
  border-radius: 0px;
  padding: 1rem;
}

.list-module .btn:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-module .btn:active {
  background-color: rgba(0, 0, 0, 0.06);
  color: #152340;
}

.list-module .btn:focus {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #152340 !important;
}

/* General */

.underline {
  text-decoration: underline;
}

.custom-form input,
.form-control {
  border: solid 2px #dee2e6 !important;
  /* border: solid 1px #4b4b4b !important; */
  border-radius: 10px !important;
}

.custom-table input.form-control.input-labelled {
  border: solid 2px #dee2e6 !important;
  /* border: solid 1px #4b4b4b !important; */
  border-radius: 10px 0px 0px 10px !important;
}

.subk-table input.form-control.input-labelled {
  border: solid 2px #dee2e6 !important;
  /* border: solid 1px #4b4b4b !important; */
  border-radius: 10px 0px 0px 10px !important;
}

.input-group > .input-group-append > .input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

button.btn.delete-btn {
  border: 2px solid #ff635f;
  color: #ff635f;
  background-color: transparent;
}

/* New Project button */
.navbar-nav .nav-link.new-project-btn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* Project Invoices list is_paid icon */
.icon-check.paid path {
  fill: #4a8950;
}

.icon-check.unpaid path {
  fill: #dadada;
}

/* New */

#register {
  background-color: #14337e;
  height: 100vh;
}

.register-page {
  width: 50% !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#register-process-status {
  color: white;
}

#register-step-contents {
  color: white;
}

#register-step-contents button.btn-alt {
  background-color: #152340;
  color: white;
  border: none;
}

#register-step-contents button.btn-main {
  border: solid 1px white;
  background-color: #152340;
  color: white;
}

#register-step-contents h2 {
  margin-bottom: 1rem;
}

#register-step-contents .row .card {
  padding: 0px;
}

#register-step-contents .row .card h4 {
  margin: 2rem;
}

#register-step-contents .row .card hr {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 0px;
}

.register-card {
  border: none !important;
  border-radius: 8px !important;
  max-width: 830px !important;
  margin-bottom: 20px;
}

.register-card h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.register-card .row {
  margin-left: 0px;
  margin-right: 0px;
}

.register-card input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.register-card input:active {
  border: none;
}

#register-summary {
  color: #152340;
}

#register-summary hr {
  margin-top: 0px !important;
}

#btn-logout {
  width: 100%;
}

#btn-upload-invoice {
  background-color: #4a8950;
  color: white;
  border-radius: 6px;
}

.badge.green-badge {
  /* TODO: Decide which green */
  /* background-color: #1c8526; */
  margin-right: 1rem;
  border-radius: 6px;
  padding: 0.5rem;
  background-color: #4a8950;
  min-width: 90px;
}

#page-upload-invoice-method .card {
  padding: 7rem;
}

#page-upload-invoice-method .svg-container svg {
  max-height: 70px;
  margin-bottom: 1.5rem;
}

.float-right  {
  float: right;
}

.float-left {
  float: left;
}

.add-company-member-modal .btn-dark {
  border-radius: 6px;
  max-width: 150px;
}

.hinzufügen-modal button {
  border-radius: 6px;
  max-width: 200px;
  padding: 10px 40px;
}

.hinzufügen-option div {
  background-color: transparent;
  color: #152340;
  border: 2px solid transparent;
  width: 100%;
}

.hinzufügen-option.active div.option-card {
  background-color: transparent;
  color: #152340;
  border: 2px solid #152340;
  border-radius: 8px;
  width: 100%;
}

.hinzufügen-option.active div.option-card:hover {
  background-color: transparent;
  color: #152340;
  border: 2px solid #152340;
  border-radius: 8px;
  width: 100%;
}

.hinzufügen-option div:hover {
  border: 2px solid transparent;
  background-color: transparent;
  color: #152340;
  cursor: pointer;
}

.hinzufügen-option .button-icon {
  float: none;
}

.button-icon {
  float: left;
  /* background-color: #152340; */
  padding: 0.25rem;
  /*
  border-radius: 8px;
  max-width: 2rem;
  max-height: 2rem;
  */
  display: flex;
  justify-content: center;
  /* margin-right: 1rem; */
}

.dashboard-aufgaben .card {
  width: 100%;
}

.dashboard-postfach .card {
  width: 100%;
}

button.btn-primary {
  border: 1px solid #14337e;
  background-color: #14337e;
  color: white;
  border-radius: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
}

button.btn-primary:hover {
  border: 1px solid #14337e;
  background-color: #14337e;
  color: white;
}

button.btn-white {
  border: 1px solid #14337e;
  background-color: white;
  color: #14337e;
  border-radius: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
}

button.btn-white:hover {
  border: 1px solid #14337e;
  background-color: white;
  color: #14337e;
}

button.btn-transparent {
  padding-left: 0px;
  background-color: transparent;
  border: 1px solid transparent;
  color: #152340;
}

button.btn-transparent svg path {
  fill: #152340;
}

button.btn-transparent:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: #152340;
}

.dashboard-projects .project-title {
  margin-bottom: 100px;
}

.projects form button {
  float: right;
  border-radius: 8px;
  max-width: 100px;
}

.projects form button:hover {
  background-color: #152340;
}

.table tr th {
  border-top: none;
  font-size: small;
  font-weight: 600;
}

.table tr td {
  font-size: small;
  font-weight: 600;
}

/* .project-list-labels.list-group-item {
  padding: 10px 0px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #e1e1e1;
} */

.projects .project-item td {
  padding-top: 22px;
  padding-bottom: 22px;
}

.projects .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.vergabeeinheit-list-item .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.vergabeeinheit .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.auftrag-list-item .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.nachtrag-list-item .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.projects .badge.incomplete {
  background-color: #979797;
}

.projects .badge.complete {
  background-color: #14337e;
}

.project-dashboard .invoices-table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.project-dashboard .invoices-table p {
  margin-bottom: 2px;
}

.project-dashboard .progress-bars {
  margin-top: 15px;
}
.project-dashboard .progress {
  height: 30px;
  border-radius: 0px;
}

.project-dashboard .progress-bar {
  background-color: #14337e;
}

/* TODO: Decide whether we use grid lines or not
.project-dashboard .progress-bars .bar-label-col {
  border-right: 1px solid #dadada;
} */

.project-dashboard .progress-bars .bar-col {
  padding-left: 0px;
}

.custom-table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 0px;
}

/* .projects .status-col {
  margin-right: 75px;
}

.projects .badge.status-col {
  margin-right: 30px;
}

.projects .client-col {
  margin-right: 50px;
} */

.vergabeeinheiten .tab-button {
  max-width: 200px;
}

.budget .tab-button {
  max-width: 200px;
}

.project-dashboard .project-dashboard-notification {
  border-radius: 0px;
}

.project-dashboard .project-dashboard-notifications {
  margin-top: 15px;
}

.project-dashboard-notification p {
  margin-bottom: 0;
}

.project-dashboard-notification p.path {
  font-size: small;
  margin-bottom: 0.5rem;
}

.project-dashboard-notification a {
  color: #14337e;
  text-decoration: underline;
}

.project-dashboard-notification a:hover {
  color: #14337e;
}

.custom-table .table-footer td {
  border-top: 2px solid #dee2e6;
}

.add-kostengruppe .modal-body {
  padding: 0px;
}

form .hinzufügen-btn {
  border-radius: 6px;
  max-width: 200px;
  padding: 10px 40px;
}

.project-budget-field {
  /* border: 1px solid #E1E1E1;
  border-radius: 4px; */
  /* padding: 7px 5px; */
  margin: 0px;
}

button.btn-modal {
  border-radius: 8px;
  max-width: 200px;
  padding: 7px 40px;
}

.navbar-avatar {
  margin-left: 0.5rem;
  width: 30px !important;
  height: 30px !important;
}

.account-page-avatar {
  width: 120px !important;
  height: 120px !important;
}

.vergabeeinheit-list-item:hover {
  cursor: pointer;
}

.auftrag-list-item:hover {
  cursor: pointer;
}

.nachtrag-list-item:hover {
  cursor: pointer;
}

.rechnung-list-item:hover {
  cursor: pointer;
}

/* Account modal header */

.account-modal .modal-header .avatar {
  width: 50px !important;
  height: 50px !important;
}

.account-modal .modal-header p {
  margin: 0px;
}

.account-modal .modal-header p.role {
  font-weight: normal;
}

.settings-profile-tab .avatar {
  width: 120px !important;
  height: 120px !important;
}

.no-padding-right {
  padding-right: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.navbar-search {
  width: auto;
}

.navbar-search * {
  height: 40px;
}

.navbar-search input {
  border: white !important;
  width: 700px !important;
  background-color: white;
  border-radius: 10px 0px 0px 10px !important;
}

.navbar-search input::placeholder {
  color: #4b4b4b85;
  font-size: 15px;
}

.navbar-search button {
  background-color: white;
  border: white;
  color: #4a4a4a;
  font-weight: 700;
  border-radius: 0px 10px 10px 0px;
}

.navbar-search button:hover {
  background-color: white;
  border: white;
  color: #14337e;
  font-weight: 700;
}

.badge-secondary {
  background-color: #717171;
}

#not_found {
  margin-top: 3rem;
}

.basedata-costgroups .costgroups-list .btn {
  background-color: white;
  color: #414141;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  min-height: 50px;
  margin-bottom: 0.5rem;
  text-align: left;
}

input.input-file {
  border: none !important;
}
